import { Mesh } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class PersonajeFour extends Mesh {
    
    constructor(loaderManager){        
        super();
        let loader = new GLTFLoader(loaderManager);
        this.onLoadModel(loader);
        this.position.x =10
        this.scale.x=6;
        this.scale.y=6;
        this.scale.z=6;
        this.name= 'p4'
        this.lookAt(0,0,0)
    }

    onLoadModel(loader){
        // derecha
        loader.load('models3D/DoNotFear.glb',(gltf) =>{
            gltf.scene.children[0].name = 'p3'
            gltf.scene.children[0].zoom = ()=>{
                this.position.x=8
                this.lookAt(0,0,0)
            }
        this.add(gltf.scene)    
        })
    }
    backPosition(){
        this.position.x=10
            this.lookAt(0,0,0)
    }
    zoom(){
        this.position.x=8
                this.lookAt(0,0,0)
    }
}