import { Mesh } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class PersonajeTwo extends Mesh {
    
    constructor(loaderManager){        
        super();
        let loader = new GLTFLoader(loaderManager);
        this.onLoadModel(loader);
        this.position.z =10
        this.scale.x=6;
        this.scale.y=6;
        this.scale.z=6;
        this.name= 'p2'
        this.lookAt(0,0,0)
    }

    onLoadModel(loader){
        // atras
        loader.load('models3D/Tribmetal.glb',(gltf) =>{
            gltf.scene.children[0].name = 'p2'
            gltf.scene.children[0].zoom = ()=>{
                this.position.z=8
                this.lookAt(0,0,0)
            }
        this.add(gltf.scene)    
        })
    }
    backPosition(){
        this.position.z=10
        this.lookAt(0,0,0)
    }
    zoom(){
        this.position.z=8
                this.lookAt(0,0,0)
    }
}