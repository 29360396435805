import { Mesh } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class Head extends Mesh {
    
    constructor(){        
        super();
        let loader = new GLTFLoader();
        this.onLoadModel(loader);
        this.position.y=18
        this.scale.x=12;
        this.scale.y=12;
        this.scale.z=12;
        this.receiveShadow = true;
        this.castShadow = true;
        this.name= 'p4'
        // this.lookAt(0,0,0)
    }

    onLoadModel(loader){
        // derecha
        loader.load('models3D/cabeza_etiqueta.glb',(gltf) =>{
            gltf.scene.children[0].name = 'p4'
            gltf.scene.children[0].zoom = ()=>{
                this.position.x=8
                this.lookAt(0,0,0)
            }
        this.add(gltf.scene)    
        })
    }
    backPosition(){
        this.position.x=10
            this.lookAt(0,0,0)
    }
    zoom(){
        this.position.x=8
                this.lookAt(0,0,0)
    }
}