<template>
  <div class="flex flex-col lg:flex-row w-full h-full overflow-y-auto">
        <div id="scene" 
        :class="getIsOpend?'w-full lg:w-7/12 h-5/6 lg:h-full':'w-full h-full'"
        class="z-20 "> 
        </div>
        <div v-if="getIsOpend && !getIsSmallScreen" class="hidden lg:contents w-5/12 h-full">
            <ProductInfoComponentVue/> 
        </div>
        <div v-if="getIsOpend && getIsSmallScreen" class="z-50 h-1/6 w-full">
            <ProductInfoMovilesComponentVue/>
        </div>
  </div>
            <ModalHoverComponentVue/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {App} from '../threeControllers/app'
import Observer, { EVENTS } from "../threeControllers/eventEmitter/Observer";
// import MenuSelectorPersonajeVue from './MenuSelectorPersonaje.vue';
import ModalHoverComponentVue from './ModalHoverComponent.vue';
import ProductInfoComponentVue from './ProductInfoComponent.vue';
import ProductInfoMovilesComponentVue from './ProductInfoMovilesComponent.vue';
export default {
    components: {
        ProductInfoMovilesComponentVue,
        ProductInfoComponentVue,
        // MenuSelectorPersonajeVue,
        ModalHoverComponentVue,
    },
    methods: {
        ...mapActions(['onChangeIsSmallScreen','onChnageIsVisibleTextHover',
        'onChangeIsFinishLoading','onChangePercentageLoading',
        'onChangeMenuOpend','onChnageDataSelected','onChnageisVerARMovileVisible'])
    },
    computed: {
        ...mapGetters(['getIsOpend','getIsSmallScreen'])
    },
    mounted(){
        if( Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)<1025){
                this.onChangeIsSmallScreen(true)
            }
        const container = document.querySelector("#scene")
        const app = new App(container)
        window.addEventListener("resize",()=>{
            this.onChangeMenuOpend(false)
            if( Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)<1025){
                this.onChangeIsSmallScreen(true)
            }else{
                this.onChangeIsSmallScreen(false)
            }
            app.onResize()
        })
        Observer.on(EVENTS.START,()=>{
            // console.log('%c mundo completamente cargado! ', 'background: #222; color: #bada55');
            this.onChangeIsFinishLoading(true)
        })
        Observer.on(EVENTS.LOADING,(percentage)=>{
            this.onChangePercentageLoading(percentage)
        })     
        Observer.on(EVENTS.SEEPERSOAJE,(name,isvisible)=>{
            this.onChnageisVerARMovileVisible(isvisible)
            this.onChnageDataSelected(name)
        })     
        Observer.on(EVENTS.SELECTPERSONAJE,(name)=>{
            this.onChnageDataSelected(name)
            this.onChangeMenuOpend(true)
        })
        Observer.on(EVENTS.OVERPERSONAJE,(payload)=>{
            this.onChnageIsVisibleTextHover(payload.state)
        })   
    }
}
</script>

<style>

</style>