
import { MeshBasicMaterial, } from "three";
import { MeshStandardMaterial } from "three";
import { TextureLoader } from "three";
import { PlaneGeometry } from "three";
import { Mesh } from "three";


export class Ground extends Mesh{

    constructor(){
        super( 
            new PlaneGeometry( 70, 70,10 ), 
            new MeshBasicMaterial( { color: 0x282828} )
             );
             this.rotation.x = - Math.PI / 2;
             let mapDB =   new TextureLoader();
			mapDB.load('/img/piso.jpg',(textureMap)=>{
				
					const uploadMaterial = new MeshStandardMaterial({
						color: 0x777777,
						// lightMap:texture,
						map:textureMap,
					})
					this.material = uploadMaterial
			})
            
    }
}