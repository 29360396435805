// import stats from 'stats-js';
import { MathUtils, sRGBEncoding, WebGLRenderer } from 'three';
import { LoadingManager } from 'three';
import { PerspectiveCamera } from 'three';
import {OrbitControls} from '../utils/OrbitControls'
import {DeviceOrientationControls} from '../utils/DeviceOrientationControls'
import Observer, { EVENTS } from './eventEmitter/Observer';
import { Scene1 } from './scenes/Scene1';
import { isDevice } from '../utils/isDevice';
// import { Euler } from 'three';
export class App{

    constructor(container){
        this.container = container
        const loaderManager = new LoadingManager()
        this.onLoadingWorld(loaderManager)
        this.camera = new PerspectiveCamera(60,
            this.container.clientWidth/this.container.clientHeight,
             0.1,
             60
            )
        this.camera.position.y = 5
        this.control ={};
        this.control.update = () =>{}
        this.enableUpdate = false
        // if movil this.control.rotateSpeed=-0.2
        this.renderer = new WebGLRenderer({
            antialias: true,
        })
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.physicallyCorrectLights = true;
        this.container.appendChild(this.renderer.domElement);

        // 
        this.sceneInitial = new Scene1(loaderManager)
        // 
        this.MouseEvents()
        // 
        this.observerEvents()
        // 
        // this.stats = stats()
		// document.body.appendChild(this.stats.dom)
		// 
        this.onResize();
		this.render();

    }
    onLoadingWorld(loader){        
        loader.onLoad = () => {
            Observer.emit(EVENTS.START)
        }
        loader.onProgress = (fileUrl,itemsloaded, itemsTotal) =>{
            const percentage = itemsloaded / itemsTotal;
            Observer.emit(EVENTS.LOADING,percentage)
        }

    }

    observerEvents(){
        Observer.on(EVENTS.MOVILEANDPERMISION,(isok)=>{
            if(isDevice() && isok){
                this.control = new DeviceOrientationControls (this.camera)
                // console.log("cargado device controls")            
            }
            else{
                this.control = new OrbitControls (this.camera, this.container);
                this.control.target.set(this.camera.position.x/1.0001,this.camera.position.y/1,this.camera.position.z/1.0001);
                this.control.target.set(this.camera.position.x/1.0001,this.camera.position.y/1,this.camera.position.z/1.00001);
                this.control.rotateSpeed=-0.2
                this.control.dampingFactor = 0.5
                this.control.minPolarAngle  = Math.PI/4;
                this.control.enableZoom = false
                this.control.enableDamping=true
            }
            this.enableUpdate =true
        })
        Observer.on(EVENTS.RESIZED,()=>{
            this.onResize();
        })  
        Observer.on(EVENTS.SELECTPERSONAJE,()=>{
            if(!isDevice()){
                this.control.enableRotate=false
            }
            else{
                this.control.enabled = false
            }
        })
        Observer.on(EVENTS.CLOSEMENURIGHT,()=>{
            if(!isDevice()){
                this.control.enableRotate=true
            }
            else{
                this.control.enabled = true
            }
        })
        let lastRotate = 0
        Observer.on(EVENTS.SELECTPERSONAJEINMENU,(numberSelected)=>{
            
            if(!isDevice() && !this.control.movile){
                switch (numberSelected) {
                    case 1:
                        switch (lastRotate) {
                            case 2:
                                this.control.gireLeft( 180 * Math.PI / 180) 
                                break;
                            case 3:
                                this.control.gireLeft( -90 * Math.PI / 180) 
                                break;
                            case 4:
                                this.control.gireLeft( 90 * Math.PI / 180) 
                                break;
                            default:
                                this.control.target.set(this.camera.position.x/1.0001,this.camera.position.y/1,this.camera.position.z/1.0001);
                                break
                        }
                        lastRotate = 1
                        break;
                    case 2:
                        this.control.target.set(this.camera.position.x/1.0001,this.camera.position.y/1,this.camera.position.z/1.0001);
                        // this.control.rotate(20  * Math.PI / 180, 0);  
                        this.control.gireLeft( 180 * Math.PI / 180) 
                        lastRotate =2
                        break;
                    case 3:
                        this.control.target.set(this.camera.position.x/1.0001,this.camera.position.y/1,this.camera.position.z/1.0001);
                        // this.control.rotate( angle* Math.PI / 180, 0);                    
                        this.control.gireLeft( 90 * Math.PI / 180) 
                        lastRotate =3
                        break;
                    case 4:
                        this.control.target.set(this.camera.position.x/1.0001,this.camera.position.y/1,this.camera.position.z/1.0001);
                        this.control.gireLeft( -90 * Math.PI / 180) 
                        lastRotate =4
                        break;
                
                    // default:
                    //     break;
                }
            }
            else{
                // console.log("selected")                
                // this.camera.rotation.set(0,-90 * Math.PI / 180,0)
                switch (numberSelected) {
                    case 1:
                        this.camera.rotation.set(0,0 * Math.PI / 180,0)
                        break;
                    case 2:
                        this.camera.rotation.set(0,180 * Math.PI / 180,0)
                        break;
                    case 3:
                        this.camera.rotation.set(0,-90 * Math.PI / 180,0)
                        break;
                    case 4:
                        this.camera.rotation.set(0,90 * Math.PI / 180,0)
                        break;
                
                    // default:
                    //     break;
                }
            }
        })
    }

    MouseEvents(){
        let isClicked =false
        let iniPositionclick = 0
        let antirebote =0 
        let drag = false;

        // sdsd

        if(isDevice()){
			this.container.addEventListener("touchstart",(e)=>{
                iniPositionclick = e.touches[0].clientX
				antirebote=0
				drag=true;
                if(this.sceneInitial.checkIsOverObject(e.changedTouches[0].clientX, e.changedTouches[0].clientY, this.renderer, this.camera)){
                    isClicked = true  
                    this.control.enableRotate =  false                  
                }
                else{
                    isClicked = false 
                    this.control.enableRotate = true                  
                }
			})	
			this.container.addEventListener("touchmove", (e)=>{
				antirebote++
				e.preventDefault();	
				drag = false;
				this.sceneInitial.onDocumentMouseMove(e.touches[0].clientX, e.touches[0].clientY, this.renderer, this.camera,this.control,isClicked,iniPositionclick)
                iniPositionclick = e.touches[0].clientX
			})
			this.container.addEventListener("touchend", (e)=> {
				e.preventDefault();
                this.control.enableRotate = true
                isClicked = false
                iniPositionclick = 0 
				if(drag && antirebote<30){
					this.sceneInitial.onDocumentMouseDown(e.changedTouches[0].clientX, e.changedTouches[0].clientY, this.renderer, this.camera);
				}				
			})
		}
        else{
            this.container.onpointerdown =(e)=>{
                iniPositionclick = e.clientX
                antirebote=0
				drag=true;
                isClicked = true
            }
            this.container.onpointerup =(e)=>{
                e.preventDefault();
                iniPositionclick = 0 
                isClicked = false
                if(drag && antirebote<10){
                    this.sceneInitial.onDocumentMouseDown(e.clientX, e.clientY, this.renderer, this.camera);
                }
            }
            this.container.onpointermove = (e) =>{
                antirebote++	
				drag = false;
                e.preventDefault();
                this.sceneInitial.onDocumentMouseMove(e.clientX, e.clientY, this.renderer, this.camera,this.control,isClicked,iniPositionclick)
                iniPositionclick = e.clientX
            }
        }
    }

    onResize() {
		this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
		this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
		this.camera.updateProjectionMatrix();
	}
    checkisLook(){
        this.camera.rotation.order = 'YXZ';
        // const euler = new Euler();
        // const rotation = euler.setFromQuaternion(this.camera.quaternion);
        // const radians =  (2 * Math.PI) + rotation.y;
        // const degrees = MathUtils.radToDeg(radians);
        const heading = this.camera.rotation.y;
        const radians = heading > 0 ? heading : (2 * Math.PI) + heading;
        const degrees = MathUtils.radToDeg(radians);
        // console.log(degrees)
        if((degrees > 333 && degrees < 360) || (degrees > 0 && degrees < 22) ){
            Observer.emit(EVENTS.SEEPERSOAJE,"p1",true)            
            return;
        }
        if(degrees > 246 && degrees < 295){
            // console.log(this.camera.rotation.y)
            Observer.emit(EVENTS.SEEPERSOAJE,"p3",true)            
            return;
        }
        if(degrees > 157 && degrees < 200){
            // console.log(this.camera.rotation.y)
            Observer.emit(EVENTS.SEEPERSOAJE,"p2",true)            
            return;
        }
        if(degrees > 68 && degrees < 113){
            // console.log(this.camera.rotation.y)
            Observer.emit(EVENTS.SEEPERSOAJE,"p4",true)            
            return;
        }
        Observer.emit(EVENTS.SEEPERSOAJE,"p1",false)
    }
    render() {
        // 1 -0.001  0.3400
        // 2 no fear: -1.24  -1.161
        // woman 0.3050   -0.353
        // provacy  1.220  1.124
        // this.control.rotate(1 * Math.PI / 180, 0);     
        this.checkisLook()
        this.onResize();
        this.renderer.render(this.sceneInitial, this.camera);
		// this.stats.update()
		this.control.update();
        this.sceneInitial.renderAnimations()
		this.renderer.setAnimationLoop(() => this.render());
	}
    

}