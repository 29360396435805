<template>
  <div v-if="isVisible" class="hidden lg:flex z-50 absolute mr-8 h-full right-0  flex-col items-center justify-between">
      <button 
      :class="!getIsOpend?'invisible':'visible'"
       @click="onChangeMenuOpend(false)" class=" cursor-pointer w-12 h-12 mt-8 flex justify-center items-center bg-white rounded-full">
       <img class="w-8 h-8" src="../assets/img/close.svg" alt="">       
       </button>
      <div>
      <div 
      @click="selectedMenuOption(1,'p1')"
      :class="getPersonajeSelected===1?'bg-EC5E2A text-white':'bg-white text-black'"
      class="w-12 h-12 mt-6 flex justify-center items-center rounded-full cursor-pointer font-medium text-lg">1</div>
      <div 
      @click="selectedMenuOption(2,'p2')"
      :class="getPersonajeSelected===2?'bg-EC5E2A text-white':'bg-white text-black'"
      class="w-12 h-12 mt-6 flex justify-center items-center rounded-full cursor-pointer font-medium text-lg">2</div>
      <div 
      @click="selectedMenuOption(3,'p3')"
      :class="getPersonajeSelected===3?'bg-EC5E2A text-white':'bg-white text-black'"
      class="w-12 h-12 mt-6 flex justify-center items-center rounded-full cursor-pointer font-medium text-lg">3</div>
      <div 
      @click="selectedMenuOption(4,'p4')"
      :class="getPersonajeSelected===4?'bg-EC5E2A text-white':'bg-white text-black'"
      class="w-12 h-12 mt-6 flex justify-center items-center rounded-full cursor-pointer font-medium text-lg">4</div>
      </div>
      <div @click="playPauseAudio()" class="cursor-pointer w-12 h-12 mb-8 flex justify-center items-center  rounded-full">
          <img v-if="!musicis" class="w-12 h-12" src="../assets/img/soundon.svg" alt="">
          <img v-else class="w-12 h-12" src="../assets/img/soundoff.svg" alt="">
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Observer, { EVENTS } from '../threeControllers/eventEmitter/Observer';
import { isDevice } from '../utils/isDevice';
export default {
 data() {
     return {
         isVisible: true,
         musicis:true
     }
 },
 computed: {
     ...mapGetters(['getIsOpend','getPersonajeSelected'])
 },
 methods: {
     ...mapActions(['onChangeMenuOpend','onChangeSelectedPersonaje','onChnageDataSelected']),
      selectedMenuOption(number,name){
       this.onChangeSelectedPersonaje(number)
       this.onChnageDataSelected(name)
     },
     playPauseAudio(){
         this.musicis = !this.musicis
         Observer.emit(EVENTS.PLAYSTOPMUSIC, this.musicis)
     }
 },
 created () {
     if(isDevice()){
         this.isVisible = false;
     }
 },
}
</script>

<style>

</style>