<template>
  <div class="absolute   z-40 mt-5 lg:mt-8 ml-5 lg:ml-8"> 
      <img class="w-52 h-9 lg:w-392  lg:h-20 object-contain " src="../assets/img/bullbenny.svg" alt="">
      <div class="pt-4 lg:pt-8">
        <p v-if="!getIsOpend" class="hel font-normal leading-tight lg:leading-normal text-sm lg:text-sm text-white">000-023 <br>POST-D4T4</p>
      </div>
      
  </div>
</template>

<script>
import {  mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getIsOpend'])
  },
}
</script>

<style>
.hel{
  font-family: Helvetica;
}
</style>