<template>
  <div class="w-full min-h-full max-h-full overflow-y-auto ">
      <div class="w-full h-80 flex">
          <div class="w-5/12 flex justify-center items-center">
            <img class="w-52 h-52  object-contain" :src="getDataMenus.qr" alt="">
          </div>
          <div class="w-7/12  text-scann flex justify-center items-start flex-col">
          <p class="text-title-scann" >mirá este outfit en ar.</p>
          <p class="text-scann pt-2">Escanea el qr con tu <br> dispositivo para acceder.</p>
          </div>
      </div>
      <div class="w-full px-4">
        <div class="w-full h-2 bg-black"></div>
      </div>
      <div class="w-full mt-14 ">
        <div class="w-5/12  flex justify-center">
          <div class="pl-2 w-40 xl:w-52">
           <p class="text-detalle">{{getDataMenus.super}}</p>
          </div>
        </div>
        <div class="w-full" v-for="x in getDataMenus.prendas" :key="x">
          <div class="w-full  mt-4 mb-9 flex justify-start">
          <div class="w-5/12 pl-2 flex justify-center items-center">
            <img class="w-40 xl:w-52  object-cover object-center" 
            :src="x.img" alt="">
          </div>
          <div class="w-7/12 ">
            <div class="text-xl text-info pb-1 xl:pb-3">
              <p>{{getDataMenus.title}}</p>
            </div>            
            <div class="text-xl text-info pb-1 xl:pb-4">
              <p>{{getDataMenus.price}}</p>
            </div>
            <div class="text-xxs text-info pb-6 max-w-xs" style="color: #919191;">
              <p>{{getDataMenus.text}}</p>
            </div>
            <div>
              <button @click="open(getDataMenus.link)" class="w-32 h-10 text-white font-bold bg-black rounded-full">Ver más</button>
            </div>
          </div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
computed: {
  ...mapGetters(["getDataMenus"])
},
methods: {
  open(link) {
    window.open(link)
  }
},
}
</script>

<style>
.text-scann{
font-family: Helvetica;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 100.9%;
/* or 11px */
letter-spacing: 0.02em;
text-transform: uppercase;
color: #919191;
}
.text-title-scann{
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-color: #000000;
  font-family: Helvetica;
font-weight: bold;
font-size: 18px;
line-height: 125.9%;
/* or 23px */
letter-spacing: 0.02em;
text-transform: uppercase;
color: #000000;
}
.text-detalle{
  font-family: Helvetica;
font-weight: bold;
font-size: 32px;
line-height: 125.9%;
/* or 23px */

letter-spacing: 0.02em;
text-transform: uppercase;
color: #000000;
}
.text-info{
font-weight: bold;
line-height: 125.9%;
/* or 23px */

letter-spacing: 0.02em;
text-transform: uppercase;

color: #000000;
}
</style>