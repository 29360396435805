<template>
  <!-- <div v-if="getIsVisibleLoadingScreen" class="fixed inset-0 w-screen h-screen bg-CB4F24 z-40 flex justify-center items-center">
      
      <div v-if="!getIsSmallScreen" class="w-640 h-412 img-loadin flex justify-end items-end"> 
        
      </div>
      <div v-else class="w-309 h-386 img-loadin-movile flex justify-center items-end">
          
          
      </div>
  </div> -->
  <div v-if="getIsVisibleLoadingScreen" class="fixed inset-0  bg-CB4F24 z-40 flex justify-center items-center">
      
       <div v-if="!getIsSmallScreen" class="w-640 h-412 img-loadin container "> 
           <div class=" absolute w-640 h-412 flex justify-end items-end z-50"> 
        <button v-if="getIsFinishLoading" @click="entryWorld" class=" mb-7 mr-7 w-229 h-69 text-white text-xl rounded-full bg-black">ENTER</button>
        <button v-else disabled  class=" mb-7 mr-7 w-229 h-69 text-black text-xl rounded-full bg-white">LOADING</button>
      </div>
           <div class="absolute w-640 h-412 flex justify-start items-end">
               <div class="mb-17 ml-6 w-48 h-4 bg-white rounded-xl">
               <div  :style="`width:${getPercentageLoading*100}%`"
                   class="w-1/2 h-full bg-EC5E2A rounded-xl"></div>
           </div>
           </div>
           <div class="absolute w-640 h-412 flex justify-start items-start">
               <div class="mt-4 ml-6">
                  <p style="letter-spacing: 0.463604px;" class="bg-ADADAD w-52 text-xl text-white font-bold">POST-D4T4 FW2K22</p>
               </div>
           </div>
           <div  class="absolute w-640 h-412 flex justify-start items-end">
               <div class="mb-6 ml-6 bg-ADADAD pr-4">
                   <img class="w-10 bg-ADADAD" src="../../assets/img/circle.png" alt="">
               </div>
           </div>
      </div>
      <div v-else class="w-309 h-386 container img-loadin-movile ">
         <div class="absolute w-309 h-386 flex justify-center items-end z-50">
                <button v-if="getIsFinishLoading" @click="entryWorld" class=" mb-8 w-40 h-12 text-white text-base rounded-full bg-black">ENTER</button>
           <button v-else disabled  class=" mb-8 w-40 h-12 text-black text-base rounded-full bg-white">LOADING</button>
        </div> 
         <div class="absolute w-309 h-386 flex justify-start items-end">
               <div class=" mb-33 ml-4 w-40 h-3 bg-white rounded-xl">
               <div  :style="`width:${getPercentageLoading*100}%`"
                   class="w-1/2 h-full bg-EC5E2A rounded-xl"></div>
           </div>
        </div> 
        <div class="absolute w-309 h-386 flex justify-start items-start">
               <div class="mt-4 ml-6">
                  <p style="letter-spacing: 0.463604px;" class="bg-ADADAD w-52 text-base text-white font-bold">POST-D4T4 FW2K22</p>
               </div>
        </div>
        <div  class="absolute w-309 h-386 flex justify-start items-end">
               <div class="mb-24 ml-4 bg-ADADAD pr-3">
                   <img class="w-8 bg-ADADAD" src="../../assets/img/circle.png" alt="">
               </div>
           </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Observer, { EVENTS } from '../../threeControllers/eventEmitter/Observer'
// import { getUA } from '../../utils/isDevice';
export default {
    computed:{
        ...mapGetters(['getIsVisibleLoadingScreen','getPercentageLoading','getIsFinishLoading','getIsSmallScreen'])
    },
    methods: {
        ...mapActions(['onChangeIsVisbleLoadingScreen']),
        entryWorld() {
            
            if ( window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function' ) {
			window.DeviceOrientationEvent.requestPermission().then( function ( response ) {
				if ( response == 'granted' ) {
                    // console.log("garanted")
                     Observer.emit(EVENTS.MOVILEANDPERMISION,true)
				}
                else{
                    // console.log("not garanted")
                     Observer.emit(EVENTS.MOVILEANDPERMISION,false)
                // alert("error1")
                    // alert("")
                }
			} ).catch( function ( error ) {
                console.error( 'THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error );
                // alert("error2")
                 Observer.emit(EVENTS.MOVILEANDPERMISION,false)
			} );
		}
        else{
                Observer.emit(EVENTS.MOVILEANDPERMISION,true)
            // alert("error?")
        }


        this.onChangeIsVisbleLoadingScreen(false)
         this.musicis = !this.musicis
         Observer.emit(EVENTS.PLAYSTOPMUSIC, this.musicis)
        }
    },

}
</script>

<style>
.img-loadin{
    background-image: url('../../assets/img/loading.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.img-loadin-movile{
    background-image: url('../../assets/img/loadingmovile.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
</style>