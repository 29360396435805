import EventEmitter from "eventemitter3";

export const EVENTS ={
    START: 'START_WEB3D',
    LOADING:'LOADING',
    RESIZED: 'RESIZED',
    SELECTPERSONAJE:'SELECTPERSONAJE',
    OVERPERSONAJE:'OVERPERSONAJE',
    CLOSEMENURIGHT:'CLOSEMENURIGHT',
    SELECTPERSONAJEINMENU:'SELECTPERSONAJEINMENU',
    PLAYSTOPMUSIC:'PLAYSTOPMUSIC',
    MOVILEANDPERMISION:'MOVILEANDPERMISION',
    SEEPERSOAJE:'SEEPERSOAJE',
}

const Observer = new EventEmitter();
export default  Observer