<template>
  <div v-if="!getIsOpend" class="flex justify-end">
      <div class="pr-5"> 
            <div @click="playPauseAudio()" class="w-11 h-11  rounded-full flex justify-center items-center">
                <img v-if="!musicis" class="w-10 h-10" src="../assets/img/soundon.svg" alt="">
                <img v-else class="w-10 h-10" src="../assets/img/soundoff.svg" alt="">
                </div>  
        </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Observer, { EVENTS } from '../threeControllers/eventEmitter/Observer';
export default {
     data() {
     return {
         musicis:true
     }
 },
    computed: {
        ...mapGetters(['getIsOpend'])
    },
    methods: {
        ...mapActions(['onChangeMenuOpend']),
        playPauseAudio(){
         this.musicis = !this.musicis
         Observer.emit(EVENTS.PLAYSTOPMUSIC, this.musicis)
     }
    },
}
</script>

<style>

</style>