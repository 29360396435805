import { AmbientLight,  Raycaster } from "three";
import { Fog } from "three";
// import { GridHelper } from "three";
import { Scene,Color } from "three";
// import {Cube} from '../objects/Cube'
import {Ground} from '../objects/Ground'
import { PersonajeOne } from "../objects/PersonajeOne";
import {PersonajeTwo } from '../objects/PersonajeTwo';
import {PersonajeFour } from '../objects/PersonajeFour'
import { PersonajeThree } from "../objects/PesonajeThree";
import {Head} from '../objects/Head'
import { Vector2 } from "three";
import { Group } from "three";
import Observer, { EVENTS } from '../eventEmitter/Observer';
import { PointLight } from "three";
import { isDevice } from "../../utils/isDevice";
// import { GUI } from "dat.gui";
let mouse = new Vector2();
let raycaster = new Raycaster();
let lastObject 

export class Scene1 extends Scene {
    constructor (loaderManager){
        super();
        this.loaderManager = loaderManager;
        this.background = new Color("#272727")
        this.fog = new Fog( 0x282828, 10, 50 );
        this.light();
        this.helpers()
        this.create(loaderManager);
        this.renderAnimations()
    }    
    create(loaderManager){
        this.groupP = new Group();
        this.personajeone = new PersonajeOne(loaderManager)
        this.personajeTwo = new PersonajeTwo(loaderManager)
        this.personajeThree = new PersonajeThree(loaderManager)
        this.personajeFour = new PersonajeFour(loaderManager)
        this.head = new Head()
        this.add(this.head)
        this.groupP.add(this.personajeone, this.personajeTwo, this.personajeThree, this.personajeFour)
        this.add(this.groupP)
        this.ground = new Ground()
        this.add(this.ground)

        Observer.on(EVENTS.CLOSEMENURIGHT,()=>{
            this.personajeone.backPosition()
            this.personajeTwo.backPosition()
            this.personajeThree.backPosition()
            this.personajeFour.backPosition()
        })
    }
    helpers(){
        // this.grid = new GridHelper(200,200,0xffffff,0xffffff)
        // this.add(this.grid)
    }
    light(){
        this.pointLight = new PointLight(0x404040,72)
        this.pointLight.position.set( 0, 10, 0 );
        this.add(this.pointLight )
        this.ambient = new AmbientLight( 0x404040)
        this.ambient.intensity = 1.3
        this.add(this.ambient)
        // var wwq = new GUI();
        // var qmuro = wwq.addFolder('light');            
        // qmuro.add(this.pointLight, 'intensity', 0, 100.0).listen();  
        // var ambient =  wwq.addFolder('ambient')         
        // ambient.add(this.ambient,'intensity',0,50).listen()
        // qmuro.open();
    }
    onDocumentMouseMove=(clientX, clientY, renderer, camera,controls,isClicked,iniPositionclick)=>{
        const canvasBounds = renderer.domElement.getBoundingClientRect();
        mouse.x = ( ( clientX - canvasBounds.left ) / ( canvasBounds.right - canvasBounds.left ) ) * 2 - 1;
        mouse.y = - ( ( clientY - canvasBounds.top ) / ( canvasBounds.bottom - canvasBounds.top) ) * 2 + 1;
        // renderer.getContext()
        raycaster.setFromCamera( mouse,camera );
        let intersects = raycaster.intersectObjects(this.children[3].children,true); 
        if(intersects[0]){
            // console.log(intersects[0].object.name)
            // intersects[0].object.scale.set(1.1,1.1,1.1)
            Observer.emit(EVENTS.OVERPERSONAJE,{name:intersects[0].object.name,state:true})
            document.body.style.cursor = 'all-scroll';
            if(!isDevice()){
                controls.enabled = false 
            }
            // intersects[0].object.material.color.set( Math.random() * 0xffffff );
			if(intersects[0].object.name ==='p4'){
                
                if(isClicked){
                    if(iniPositionclick>clientX){
                        this.personajeThree.rotation.y +=0.05
                        lastObject = intersects[0].object
                    }
                    else{
                        this.personajeThree.rotation.y  -=0.05                
                        lastObject = intersects[0].object
                    }
                }
            }
            else{
            let rotateaxis = 'z'
            if(intersects[0].object.name ==='p1' ){
                rotateaxis = 'y'
            }
            if(isClicked){
                if(iniPositionclick>clientX){
                    intersects[0].object.rotation[rotateaxis] +=0.05
                    lastObject = intersects[0].object
                }
                else{
                    intersects[0].object.rotation[rotateaxis]  -=0.05                
                    lastObject = intersects[0].object
                }
            }
            }
		}
        else{
            Observer.emit(EVENTS.OVERPERSONAJE,{name:'none',state:false})
            // controls.enabled = true
            document.body.style.cursor = 'default';
        }
        if(isClicked && lastObject !== null){
            if(lastObject.name ==='p4'){
                
                if(isClicked){
                    if(iniPositionclick>clientX){
                        this.personajeThree.rotation.y +=0.05
                      
                    }
                    else{
                        this.personajeThree.rotation.y  -=0.05                
                      
                    }
                }
            }
            else{
                let rotateaxis = 'z'
            if(lastObject.name ==='p1'){
                rotateaxis = 'y'
            }
            if(iniPositionclick>clientX){
                lastObject.rotation[rotateaxis]  +=0.05
            }
            else{
                lastObject.rotation[rotateaxis]  -=0.05      
            }
            }
            
        }
        else{
            lastObject = null
            if(!isDevice()){
                controls.enabled = true
            }
        }
    }
    onDocumentMouseDown = (clientX, clientY, renderer, camera  ) =>{
        const canvasBounds = renderer.domElement.getBoundingClientRect();
        mouse.x = ( ( clientX - canvasBounds.left ) / ( canvasBounds.right - canvasBounds.left ) ) * 2 - 1;
        mouse.y = - ( ( clientY - canvasBounds.top ) / ( canvasBounds.bottom - canvasBounds.top) ) * 2 + 1;
        raycaster.setFromCamera( mouse,camera );
        let intersects = raycaster.intersectObjects(this.children[3].children,true); 
        if(intersects[0]){
            Observer.emit(EVENTS.SELECTPERSONAJE,intersects[0].object.name)
            this.personajeone.zoom()
            this.personajeTwo.zoom()
            this.personajeThree.zoom()
            this.personajeFour.zoom()
        }
    }
    checkIsOverObject = (clientX, clientY, renderer, camera  ) =>{
        const canvasBounds = renderer.domElement.getBoundingClientRect();
        mouse.x = ( ( clientX - canvasBounds.left ) / ( canvasBounds.right - canvasBounds.left ) ) * 2 - 1;
        mouse.y = - ( ( clientY - canvasBounds.top ) / ( canvasBounds.bottom - canvasBounds.top) ) * 2 + 1;
        raycaster.setFromCamera( mouse,camera );
        let intersects = raycaster.intersectObjects(this.children[3].children,true); 
        if(intersects[0]){
            return true;
        }
        return false
    }

    renderAnimations(){
        this.personajeone.rotation.y +=0.003
        this.personajeTwo.rotation.y +=0.003
        this.personajeThree.rotation.y +=0.003
        this.personajeFour.rotation.y +=0.003
        this.head.rotation.y +=0.002
    }

}

// Note: if you try move the camera you need add this code in jsm/OrbitControls.js
// this.rotate = function(rotateX, rotateY) {
    // rotateX angle to mouse X
//     let element = scope.domElement;
//     let x = (rotateX * element.clientHeight) / (Math.PI * 2);
    // rotateY angle to mouse Y
//     let y = (rotateY * element.clientHeight) / (Math.PI * 2);
    // Add to previous mouse point
//     x = rotateStart.x + x;
//     y = rotateStart.y + y;
//     handleMouseMoveRotate({clientX: x, clientY: y});
//  }