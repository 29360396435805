import { createStore } from 'vuex'
import loading from './modules/loading'
import Menus from './modules/Menus'
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    loading,
    Menus,
  }
})
