<template>
  <div v-if="!getIsOpend" class="flex justify-end">
      <div v-if="getisVerARMovileVisible" class="pr-5"> 
            <div @click="open(getDataMenus.ar)" class=" rounded-full flex justify-center items-center">
                <img  class="w-90 h-w-90" src="../assets/img/cubeverar.svg" alt="">
                </div>  
        </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import Observer, { EVENTS } from '../threeControllers/eventEmitter/Observer';
export default {
     data() {
     return {
     }
 },
    computed: {
        ...mapGetters(['getIsOpend','getDataMenus','getisVerARMovileVisible'])
    },
    methods: {
        ...mapActions(['onChangeMenuOpend']),
        open(url){
            window.open(url,"ar","download");
        }
    },
}
</script>

<style>

</style>