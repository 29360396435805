<template>
  <div class="w-full z-50">
    <div  class="flex justify-between transform -translate-y-14">
      <div  @click="onChangeMenuOpend(false)" class="ml-5 cursor-pointer w-10 h-10 bg-white rounded-full flex justify-center items-center">
          <img class="w-5 h-5" src="../assets/img/close.svg" alt="">       
      </div>
      <div @click="openLink(getDataMenus.ar)" class="mr-5 w-40 h-10 ">
            <img src="../assets/img/verenar.svg" alt="">
      </div>
  </div>
      <div class="w-full h-16vh flex flex-col justify-start  transform -translate-y-10">
          <div class="w-full h-5/6  flex justify-center items-center">
            <div class="w-4/12 flex justify-start">
              <div class="pl-5"><p class="text-conjuntos text-left text-xxs font-bold text-gray-500 uppercase pr-2 ">
                <strong class="text-black">deslizá abajo</strong>
                 <br> para ver cada <br> prenda. </p></div>
          </div>
          <div class="w-8/12 flex justify-evenly items-center">
              <div 
              @click="selectedMenuOption(1,'p1')"
                :class="getPersonajeSelected===1?'bg-EC5E2A text-white':'bg-white text-black border border-black'"
              class="w-10 h-10  cursor-pointer  rounded-full flex justify-center items-center">1</div>
              <div 
              @click="selectedMenuOption(2,'p2')"
                :class="getPersonajeSelected===2?'bg-EC5E2A text-white':'bg-white text-black border border-black'"
              class="w-10 h-10  cursor-pointer  rounded-full flex justify-center items-center">2</div>
              <div 
              @click="selectedMenuOption(3,'p3')"
                :class="getPersonajeSelected===3?'bg-EC5E2A text-white':'bg-white text-black border border-black'"
              class="w-10 h-10  cursor-pointer  rounded-full flex justify-center items-center">3</div>
              <div 
              @click="selectedMenuOption(4,'p4')"
                :class="getPersonajeSelected===4?'bg-EC5E2A text-white':'bg-white text-black border border-black'"
              class="w-10 h-10  cursor-pointer  rounded-full flex justify-center items-center">4</div>
          </div>
          </div>
          <div class="pl-5 flex  transform translate-y-2">
            <p class="text-black text-3xl font-bold uppercase">{{getDataMenus.super}}</p>
          </div>
      </div>
      <div class="w-full flex flex-col items-center">
        <div v-for="x in getDataMenus.prendas " :key="x" class="w-full px-5 ">
        <div class="w-full flex flex-col items-center ">
        <div class="mb-4 h-px bg-black w-full"></div>
      </div>
       <div class="w-full flex justify-center">
            <img class="w-full  object-contain" :src="x.img" alt="">
       </div>
       <div class="w-full">
        <div class="">
            <div class="text-xl text-info pb-5 pt-4">
              <p>{{getDataMenus.title}}</p>
            </div>            
            <div class="text-xl text-info pb-6">
              <p>{{getDataMenus.price}}</p>
            </div>
            <!-- <div class="text-sm text-info pb-4 font-medium">
              <p>TALLAS DISPONIBLES: 1/2-ROSA</p>
            </div> -->
            <div class="text-xs text-info pb-6"  style="color: #919191;">
              <p>{{getDataMenus.text}}</p>
            </div>
            <div class="pb-5">
              <button @click="openLink(getDataMenus.link)" class="w-32 h-10 text-white font-bold bg-black rounded-full">Ver más</button>
            </div>
          </div>
       </div>
      </div>
      <div class="w-11/12 flex flex-col items-center ">
        <div class=" h-px bg-black w-full"></div>
      </div>
      <div @click="top()" class="rounded-md mt-4 mb-4 w-11/12 h-10 border border-gray-500 flex justify-center items-center">
        <p class="text-sm font-bold  uppercase" style="color: #919191;">VER DROP EN LA WEB</p>
      </div>
      </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
computed: {
     ...mapGetters(['getIsOpend','getPersonajeSelected','getDataMenus'])
 },
 methods: {
     ...mapActions(['onChangeMenuOpend','onChnageDataSelected','onChangeSelectedPersonaje']),
     openLink(ar){
       window.open(ar,"ar","download")
     },
     selectedMenuOption(number,name){
       this.onChangeSelectedPersonaje(number)
       this.onChnageDataSelected(name)
     },
     top(){
       window.open("https://www.bullbenny.com.ar/post-d4t4/")
     }
 },
}
</script>
<style>
.text-subtitle{
  font-family: Helvetica;
font-style: normal;
font-weight: bold;
font-size: 9px;
letter-spacing: 0.02em;
text-transform: uppercase;

color: #848484;
}
</style>