<template>
<div>
  <audio  autoplay   id="audio">
  <source src="../assets/music/music.mp3" type="audio/mpeg">
Your browser does not support the audio element.
</audio>
</div>
<HeaderComponentVue/>
 <LoadingComponentVue/>
 <div class="fixed bg-white w-full h-full">
    <Home3DComponentVue/>
  </div> 
  <BottomTextComponentVue/>
  <div v-if="!getIsSmallScreen" class="fixed right-0"
    :class="getIsOpend?'mr-41vw':'mr-2'">    
    <div class="h-screen ">
        <MenuSelectorPersonajeVue/>
      </div>      
  </div>
  <div v-if="getIsSmallScreen" class="fixed top-0"
    :class="getIsOpend?'mb-16vh':'mb-0'">    
    <div class="w-screen  pt-5 ">
      <MenuMovileComponentVue/>
      </div>      
  </div>
  <div v-if="getIsSmallScreen" class="fixed bottom-0"
    :class="getIsOpend?'mb-16vh':'mb-0'">    
    <div class="w-screen  pb-5 ">
      <VerARCubeMovileVue/>
      </div>      
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BottomTextComponentVue from '../components/BottomTextComponent.vue'
import HeaderComponentVue from '../components/HeaderComponent.vue'
import Home3DComponentVue from '../components/Home3DComponent.vue'
import LoadingComponentVue from '../components/Loading/LoadingComponent.vue'
import MenuMovileComponentVue from '../components/MenuMovileComponent.vue'
import MenuSelectorPersonajeVue from '../components/MenuSelectorPersonaje.vue'
import VerARCubeMovileVue from '../components/VerARCubeMovile.vue'
import Observer, { EVENTS } from '../threeControllers/eventEmitter/Observer'
export default {
  name: 'Home',
  data() {
    return {
      audio: {},
    };
  },
  components: {
    MenuMovileComponentVue,
    VerARCubeMovileVue,
    MenuSelectorPersonajeVue,
    BottomTextComponentVue,
    HeaderComponentVue,
    Home3DComponentVue,   
    LoadingComponentVue,
  },
  computed: {
    ...mapGetters(['getIsOpend','getIsSmallScreen'])
  },
  methods: {
    ...mapActions(['onChangeIsSmallScreen'])
  },
   mounted() {
     console.log(" V1.5.0")
    this.audio = document.getElementById("audio");
    this.audio.pause();
    Observer.on(EVENTS.PLAYSTOPMUSIC,(is)=>{
      if(is){
        this.audio.play();
      }
      else{
        this.audio.pause();
      }

    })
  },
}
</script>
