
export default {
    state: {
        isVisisbleLoadingScreen:true,
        isFinishLoading:false,
        loadingPercentage:0,
    },
    mutations: {
        setIsVisibleLoadingScreen(state,isVisible){
            state.isVisisbleLoadingScreen = isVisible;
        },
        setPercentageLoading(state,percentage){
            state.loadingPercentage = percentage;
        },
        setIsFinishLoading(state,isFinish){
            state.isFinishLoading = isFinish
        }
    },
    actions: {
        onChangeIsVisbleLoadingScreen({commit},isVisible){
            commit('setIsVisibleLoadingScreen',isVisible);
        },
        onChangePercentageLoading({commit},percentage){
            commit('setPercentageLoading',percentage)
        },
        onChangeIsFinishLoading({commit},isFinish){
            commit('setIsFinishLoading',isFinish)
        }
    },
    getters:{
        getIsVisibleLoadingScreen : state => state.isVisisbleLoadingScreen,
        getPercentageLoading: state => state.loadingPercentage,
        getIsFinishLoading: state => state.isFinishLoading
    },
  }
  