import Observer, { EVENTS } from "../../threeControllers/eventEmitter/Observer";
const dataMenus = [
    {
        name: "p1",
        super:'FIRE_004',
        title:'JEAN CAMO [ CANNIBAL CORPSE ]',
        price:'$11.500',
        text:'JEAN ANCHO DE GABARDINA ESTAMPADA CON PATRON CAMUFLADO. APTO PARA INCURSIONES EN LA CYBERFAUNA AUTOCTONA DEL METAVERSO.',
        qr:'./img/qrs/fire.svg',
        ar:'https://bullbenny.veintiuno.studio/Fire-004.html',
        link:'https://www.bullbenny.com.ar/productos/jean-cannibal-corpse-azul/',
        prendas:[
            {
                img:'./img/prendas/_jean-cannibalcorpse-azul_01.png'
            }
        ]
    },
    {
        name: "p2",
        super:'TRIBMETAL',
        title:'BUZO OVERSIZED  [ TRIBMETAL ]',
        price:'$12.990',
        text:'HOODIE APTO PARA AMPLIO ESPECTRO DE TEMPERATURAS EN CPU. ESTAMPA PROGRAMADA GRÁFICAMENTE EN EMULADOR DE METALES VIRTUALES DE ALTA DENSIDAD.',
        qr:'./img/qrs/tribmetal.svg',
        ar:'https://bullbenny.veintiuno.studio/Tribmetal.html',
        link:'https://www.bullbenny.com.ar/productos/buzo-tribmetal-negro/',
        prendas:[
            {
                img:'./img/prendas/BuzoTribmetal.png'
            }
        ]
    },
    {
        name: "p3",
        super:'DO NOT FEAR',
        title:'REMERA [ DO NOT FEAR ]',
        price:'$5.990',
        text:'REMERA OVERSIZED ESTAMPADA, 100% ALGODON, REQUERIDA. OTORGA AL AVATAR 10 PUNTOS EXTRA DE CORAJE.',
        qr:'./img/qrs/donotfear.svg',
        ar:'https://bullbenny.veintiuno.studio/DoNotFear.html',
        link:'https://www.bullbenny.com.ar/productos/remera-do-not-fear-acid/',
        prendas:[
            {
                img:'./img/prendas/BBRemeraDonotFear.png'
            }
        ]
    },
    {
        name: "p4",
        super:'PIRACY',
        title:'REMERA [ piracy ]',
        price:'$5.390',
        text:'REMERA OVERSIZED 100% ALGODÓN, UNIFORME DE LAS FUERZAS ESPECIALES CREATIVAS DEL UNIVERSO DIGITAL.',
        qr:'./img/qrs/privacy.svg',
        ar:'https://bullbenny.veintiuno.studio/Piracy.html',
        link:'https://www.bullbenny.com.ar/productos/remera-piracy-blanco/',
        prendas:[
            {
                img:'./img/prendas/BBRemeraPiracy3.png'
            }
        ]
    }
]
export default {
    state: {
        isMenuOpend: false,
        isVerARMovileVisible:false,
        personajeSelected:1,
        isVisibleTextHover:false,
        isSamllScreen:false,
        dataSelected:{}
    },
    mutations: {
        setisVerARMovileVisible(state,isvisible){
            state.isVerARMovileVisible = isvisible
        },
        setIsMenuOpend(state,isOpend){
            state.isMenuOpend =isOpend;
        },
        setPersonajeSelected(state,selected){
            state.personajeSelected = selected;
        },
        setIsVisibleTextHover(state,isVisible){
            state.isVisibleTextHover = isVisible;
        },
        setIsSmallScreen(state,is){
            state.isSamllScreen = is
        },
        setDataSelected(state,data){
            state.dataSelected = data
        }
    },
    actions: {
        onChnageisVerARMovileVisible({commit},isvisible){
            commit('setisVerARMovileVisible',isvisible)
        },
        onChangeIsSmallScreen({commit},is){
            commit('setIsSmallScreen',is)
        },
        onChangeMenuOpend({commit},isOpend){
            if(!isOpend){
                Observer.emit(EVENTS.CLOSEMENURIGHT)
            }
            Observer.emit(EVENTS.RESIZED)
            commit('setIsMenuOpend',isOpend)
        },
        onChangeSelectedPersonaje({commit,getters},numberPersonaje){
            // console.log(numberPersonaje)
            Observer.emit(EVENTS.SELECTPERSONAJEINMENU,numberPersonaje,getters.getIsSmallScreen)
            commit('setPersonajeSelected',numberPersonaje)
        },
        onChnageIsVisibleTextHover({commit},isVisible){
            commit('setIsVisibleTextHover',isVisible)
        },
        onChnageDataSelected({commit},personaje){
            dataMenus.forEach(info =>{
                if(info.name === personaje){
                    commit('setDataSelected',info)
                    return
                }
            })
        }
    },
    getters:{
        getisVerARMovileVisible: state => state.isVerARMovileVisible,
        getIsOpend : state => state.isMenuOpend,
        getPersonajeSelected : state => state.personajeSelected,
        getIsVisibleTextHover : state => state.isVisibleTextHover,
        getIsSmallScreen: state => state.isSamllScreen,
        getDataMenus : state=> state.dataSelected
    },
  }
  