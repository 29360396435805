<template>
  <div 
  v-if="getIsVisibleTextHover && !getIsOpend && !getIsSmallScreen"
  class="z-20 bottom-1/2 fixed  pl-5 pb-5 lg:pl-8 lg:pb-8 flex justify-center">
      <div class="w-270 h-98 bg-hover-text"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
 computed: {
     ...mapGetters(['getIsOpend','getIsVisibleTextHover','getIsSmallScreen'])
 },
}
</script>

<style>
.bg-hover-text{
  background-image: url('../assets/img/texthover.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>