<template>
<div v-if="!getIsSmallScreen || (getIsSmallScreen && !getIsOpend)">
  <div v-if="!getIsSmallScreen" class="z-30 fixed bottom-0 left-0">
      <div class="pl-5 pb-5 lg:pl-8 lg:pb-8">
          <a class="w-230 h-12 flex items-center justify-center border rounded-full border-white" href="https://www.bullbenny.com.ar/post-d4t4/">
          <p class="hel uppercase font-normal text-sm text-white">Ver drop en la web</p>
          <img class="w-4 ml-2" src="../assets/img/arrow.svg" alt="">
          </a>
      </div>
  </div>
  <div v-else class="z-30 uppercase fixed bottom-0 left-0">
    <div class="ml-5 mb-3">
      <button @click="opencoll()" class="bg-EC5E2A w-36 h-8 rounded-full uppercase text-white text-sm font-bold">VER COLECCIÓN</button>
    </div>
      <div class="ml-5 mb-5">
        <div class="flex justify-start items-center">
          <img class="w-4  mr-2" src="../assets/img/mexpand.svg" alt="">
          <p class="text-white text-sm mb-1 font-semibold">Explorá el espacio</p>
        </div>
        <div class="  flex justify-start items-center">
          <img class="w-4 mr-2" src="../assets/img/circles.svg" alt="">
          <p class="text-white text-sm font-semibold">Tap para ver prendas</p>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
        ...mapGetters(['getIsOpend','getIsSmallScreen'])
    },
    methods: {
      opencoll() {
        window.open('https://www.bullbenny.com.ar/post-d4t4/')
      }
    },
}
</script>

<style>

</style>